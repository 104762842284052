import { useTheme } from '@emotion/react';
import { LoadingButton } from '@mui/lab';
import { Card, Checkbox, Grid, TextField } from '@mui/material';
import { Box, styled } from '@mui/system';
import { navigate, Link } from "gatsby"
import useAuth from '../hooks/userAuth';
import { Formik } from 'formik';
import { useState } from 'react';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';

const FlexBox = styled(Box)(() => ({ display: 'flex', alignItems: 'center' }));

const JustifyBox = styled(FlexBox)(() => ({ justifyContent: 'center' }));

const ContentBox = styled(JustifyBox)(() => ({
  height: '100%',
  padding: '32px',
  background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRegister = styled(JustifyBox)(() => ({


  '& .card': {
    maxWidth: 800,
    minHeight: 400,
    margin: '1rem',
    display: 'flex',
    borderRadius: 12,
    alignItems: 'center',
  },
}));

// inital login credentials
const initialValues = {
  email: '',
  password: '',
  username: '',
  remember: true,
  mobile: '',
};

// form field validation schema
const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be 6 character length')
    .required('Password is required!'),
  email: Yup.string().email('Invalid Email address').required('Email is required!'),
  mobile: Yup.string().required('Mobile Number is required')
});

const JwtRegister = () => {
  const dispatch = useDispatch()
  const theme = useTheme();
  const { register } = useAuth();
  const [loading, setLoading] = useState(false);
  const [registerSuccessful, setRegistrationSuccessful] = useState(false)
  const [message, setMessage] = useState("")
  const handleFormSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await register(values.email, values.username, values.password, values.mobile);
      if (response.code === 200) {
        setRegistrationSuccessful(true)
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMessage(e.message)
    }
  };

  return (
    <JWTRegister>
      <Card className="card">
        <Grid container>
          <Grid item sm={12} xs={12}>
            {
              !registerSuccessful && <Box p={4} height="100%">
                <Formik
                  onSubmit={handleFormSubmit}
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                >
                  {({ values, errors, touched, handleChange, handleBlur, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>

                      <TextField
                        fullWidth
                        size="small"
                        type="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.email}
                        onChange={handleChange}
                        helperText={touched.email && errors.email}
                        error={Boolean(errors.email && touched.email)}
                        sx={{ mb: 3 }}
                      />
                      <TextField
                        fullWidth
                        size="small"
                        name="password"
                        type="password"
                        label="Password"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.password}
                        onChange={handleChange}
                        helperText={touched.password && errors.password}
                        error={Boolean(errors.password && touched.password)}
                        sx={{ mb: 2 }}
                      />

                      <TextField
                        fullWidth
                        size="small"
                        type="mobile"
                        name="mobile"
                        label="Mobile Number"
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.mobile}
                        onChange={handleChange}
                        helperText={touched.mobile && errors.mobile}
                        error={Boolean(errors.mobile && touched.mobile)}
                        sx={{ mb: 3 }}
                      />

                      <FlexBox gap={1} alignItems="center">
                        <Checkbox
                          size="small"
                          name="remember"
                          onChange={handleChange}
                          checked={values.remember}
                          sx={{ padding: 0 }}
                        />

                        {
                          <p fontSize={13}>
                            I agree to receive marketing email from OpenFengShui.com
                          </p>
                        }
                      </FlexBox>
                      <LoadingButton
                        type="submit"
                        color="primary"
                        loading={loading}
                        variant="contained"
                        sx={{ mb: 2, mt: 3 }}
                      >
                        Create Account
                      </LoadingButton>
                      <br />
                      {
                        message
                      }
                    </form>
                  )}


                </Formik>


              </Box>
            }

            {
              registerSuccessful && <Box p={4} height="100%">
                <h3> Registration Successful </h3>
                <p>
                  Please check your email to verify your account.
                </p>

              </Box>
            }
          </Grid>
        </Grid>
      </Card>
    </JWTRegister>
  );
};

export default JwtRegister;
